// MODULES
import {connect as _reduxConnect} from "react-redux";
import {bindActionCreators as _bindActionCreators} from "redux";

export const createB64Context = function (appClientName, user, ssnOrg, draftCaseId)
{
	let dateString = new Date()
	dateString = dateString.toISOString().split('T')[0];
	dateString = dateString + new Date().getHours() + ":00";
	let checkString = md5(ssnOrg + dateString + "fairclient");
	let jsonData = {
		partner: user.getData(),
		customer: {id: ssnOrg, check: checkString}
	};

	if (draftCaseId !== undefined && draftCaseId)
	{
		jsonData.draftCaseId = draftCaseId;
	}

	let partnerString = JSON.stringify(jsonData);

	return btoa(unescape(encodeURIComponent(partnerString)));
}

export const findMedian = function (arr)
{
	arr.sort((a, b) => a - b);
	const middleIndex = Math.floor(arr.length / 2);

	if (arr.length % 2 === 0)
	{
		return Math.ceil((arr[middleIndex - 1] + arr[middleIndex]) / 2);
	}
	else
	{
		return arr[middleIndex];
	}
}


export const md5 = (inputString) =>
{
	const hc = "0123456789abcdef";

	function rh(n)
	{
		let j, s = "";
		for (j = 0; j <= 3; j++)
		{
			s += hc.charAt((n >> (j * 8 + 4)) & 0x0F) + hc.charAt((n >> (j * 8)) & 0x0F);
		}
		return s;
	}

	function ad(x, y)
	{
		const l = (x & 0xFFFF) + (y & 0xFFFF);
		const m = (x >> 16) + (y >> 16) + (l >> 16);
		return (m << 16) | (l & 0xFFFF);
	}

	function rl(n, c)
	{
		return (n << c) | (n >>> (32 - c));
	}

	function cm(q, a, b, x, s, t)
	{
		return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
	}

	function ff(a, b, c, d, x, s, t)
	{
		return cm((b & c) | ((~b) & d), a, b, x, s, t);
	}

	function gg(a, b, c, d, x, s, t)
	{
		return cm((b & d) | (c & (~d)), a, b, x, s, t);
	}

	function hh(a, b, c, d, x, s, t)
	{
		return cm(b ^ c ^ d, a, b, x, s, t);
	}

	function ii(a, b, c, d, x, s, t)
	{
		return cm(c ^ (b | (~d)), a, b, x, s, t);
	}

	function sb(x)
	{
		let i;
		const nblk = ((x.length + 8) >> 6) + 1;
		const blks = new Array(nblk * 16);
		for (i = 0; i < nblk * 16; i++)
		{
			blks[i] = 0;
		}
		for (i = 0; i < x.length; i++)
		{
			blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
		}
		blks[i >> 2] |= 0x80 << ((i % 4) * 8);
		blks[nblk * 16 - 2] = x.length * 8;
		return blks;
	}

	let i, x = sb("" + inputString), a = 1732584193, b = -271733879, c = -1732584194, d = 271733878, olda, oldb, oldc,
		oldd;
	for (i = 0; i < x.length; i += 16)
	{
		olda = a;
		oldb = b;
		oldc = c;
		oldd = d;
		a = ff(a, b, c, d, x[i], 7, -680876936);
		d = ff(d, a, b, c, x[i + 1], 12, -389564586);
		c = ff(c, d, a, b, x[i + 2], 17, 606105819);
		b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
		a = ff(a, b, c, d, x[i + 4], 7, -176418897);
		d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
		c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
		b = ff(b, c, d, a, x[i + 7], 22, -45705983);
		a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
		d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
		c = ff(c, d, a, b, x[i + 10], 17, -42063);
		b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
		a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
		d = ff(d, a, b, c, x[i + 13], 12, -40341101);
		c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
		b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
		a = gg(a, b, c, d, x[i + 1], 5, -165796510);
		d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
		c = gg(c, d, a, b, x[i + 11], 14, 643717713);
		b = gg(b, c, d, a, x[i], 20, -373897302);
		a = gg(a, b, c, d, x[i + 5], 5, -701558691);
		d = gg(d, a, b, c, x[i + 10], 9, 38016083);
		c = gg(c, d, a, b, x[i + 15], 14, -660478335);
		b = gg(b, c, d, a, x[i + 4], 20, -405537848);
		a = gg(a, b, c, d, x[i + 9], 5, 568446438);
		d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
		c = gg(c, d, a, b, x[i + 3], 14, -187363961);
		b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
		a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
		d = gg(d, a, b, c, x[i + 2], 9, -51403784);
		c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
		b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
		a = hh(a, b, c, d, x[i + 5], 4, -378558);
		d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
		c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
		b = hh(b, c, d, a, x[i + 14], 23, -35309556);
		a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
		d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
		c = hh(c, d, a, b, x[i + 7], 16, -155497632);
		b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
		a = hh(a, b, c, d, x[i + 13], 4, 681279174);
		d = hh(d, a, b, c, x[i], 11, -358537222);
		c = hh(c, d, a, b, x[i + 3], 16, -722521979);
		b = hh(b, c, d, a, x[i + 6], 23, 76029189);
		a = hh(a, b, c, d, x[i + 9], 4, -640364487);
		d = hh(d, a, b, c, x[i + 12], 11, -421815835);
		c = hh(c, d, a, b, x[i + 15], 16, 530742520);
		b = hh(b, c, d, a, x[i + 2], 23, -995338651);
		a = ii(a, b, c, d, x[i], 6, -198630844);
		d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
		c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
		b = ii(b, c, d, a, x[i + 5], 21, -57434055);
		a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
		d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
		c = ii(c, d, a, b, x[i + 10], 15, -1051523);
		b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
		a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
		d = ii(d, a, b, c, x[i + 15], 10, -30611744);
		c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
		b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
		a = ii(a, b, c, d, x[i + 4], 6, -145523070);
		d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
		c = ii(c, d, a, b, x[i + 2], 15, 718787259);
		b = ii(b, c, d, a, x[i + 9], 21, -343485551);
		a = ad(a, olda);
		b = ad(b, oldb);
		c = ad(c, oldc);
		d = ad(d, oldd);
	}
	return rh(a) + rh(b) + rh(c) + rh(d);
}

export const createUid = function ()
{
	const abc = "abcdefghijklmnopqrstuvwxyz";
	const ABC = abc.toUpperCase();
	const now = (new Date()).getTime().toString();
	let result = "";

	for (let i = 0; i < now.length; i++)
	{
		const rand = Math.round(Math.random());
		result += (rand === 0 ? abc : ABC)[now[i]];
	}

	return result;
};

export const getAllIndexes = function (arr, val)
{
	const result = [];
	for (let i = 0; i < arr.length; i++)
	{
		if (arr[i] === val)
		{
			result.push(i);
		}
	}
	return result;
};

export const loop = function (n, fn, mapValues = false)
{
	if (mapValues === true)
	{
		const returnValue = [];
		for (let i = 0; i < n; i++)
		{
			returnValue.push(fn(i));
		}
		return returnValue;
	}
	else
	{
		for (let i = 0; i < n; i++)
		{
			fn(i);
		}
	}
};

export const isType = function (value, typeName)
{
	return typeof value !== "undefined" && value.constructor.toString().indexOf(typeName) >= 0;
};

export const delay = function (duration)
{
	return new Promise(resolve =>
	{
		const timeout = setTimeout(() => resolve(timeout), duration);
	});
};

export const inArray = function (needle, haystack)
{
	for (let i = 0, n = haystack.length; i < n; i++)
	{
		if (haystack[i] === needle)
		{
			return true;
		}
	}
	return false;
};

export const isEmpty = function (obj)
{
	for (let key in obj)
	{
		if (obj.hasOwnProperty(key))
		{
			return false;
		}
	}
	return true;
};

export const validateEmail = function (email)
{
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

export const createClassName = function (initialClassName = "", conditionals = {})
{
	let conditionalClassName = "";
	Object.keys(conditionals).forEach(className =>
	{
		if (!!conditionals[className])
		{
			conditionalClassName += " " + className;
		}
	});

	return initialClassName + (initialClassName ? conditionalClassName : conditionalClassName.substring(1));
};

export const connectRedux = function (stateToProps, dispatchProps = {}, ComponentClass)
{
	const mapStateToProps = (state) => ({reducers: {...stateToProps(state)}});
	const mapDispatchToProps = (dispatch) =>
	{
		const result = {actions: {}, dispatch};
		Object.keys(dispatchProps).forEach(key => result.actions[key] = _bindActionCreators(dispatchProps[key], dispatch));
		return result;
	};

	return _reduxConnect(mapStateToProps, mapDispatchToProps)(ComponentClass);
};

export const parseQueryString = function (query)
{
	const result = {};
	if (query[0] === "?")
	{
		query = query.substring(1, query.length);
	}

	query.split("&").forEach(param =>
	{
		param = param.split("=");
		result[param[0]] = (() =>
		{
			try
			{
				return JSON.parse(param[1]);
			}
			catch (e)
			{
				return param[1];
			}
		})();
	});

	return result;
};

export const stringifyQueryParams = function (params)
{
	const keys = Object.keys(params);
	return "?" + keys.map(key =>
	{
		let value = params[key];
		if (typeof value === "object")
		{
			value = JSON.stringify(value);
		}
		return key + "=" + encodeURIComponent(value);
	}).join("&");
};

export const createConstants = function (prefix, constants)
{
	const result = {};
	constants.forEach(c =>
	{
		const constant = `${prefix}_${c}`;
		result[constant] = constant;
	});
	Object.freeze(result);
	return result;
};

export const closestInArray = function (num, arr, returnIndex = false)
{
	let curr = arr[0];
	let diff = Math.abs(num - curr);

	for (let i = 0; i < arr.length; i++)
	{
		const newdiff = Math.abs(num - arr[i]);
		if (newdiff < diff)
		{
			diff = newdiff;
			curr = arr[i];
		}
	}

	return returnIndex === true ? arr.indexOf(curr) : curr;
};

export const getWindowDimensions = function ()
{
	return {
		width: window.innerWidth,
		height: window.innerHeight
	};
};

export const requestAnimFrame = (function ()
{
	const _raf = window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		function (callback)
		{
			window.setTimeout(callback, 1000 / 60)
		};
	return _raf ? _raf.bind(window) : null;
})();

export const animate = function (props)
{
	// const duration = 1000 * props.duration;
	const duration = props.duration;
	const end = +new Date() + duration;

	function step()
	{
		const current = +new Date();
		const remaining = end - current;

		if (remaining < 60)
		{
			props.run(1);  // 1 = progress is at 100%
			return;
		}
		else
		{
			const rate = 1 - remaining / duration;
			props.run(rate);
		}

		requestAnimFrame(step);
	}

	step();
};

export const getClosestElementByQuery = function (elem, selector, searchItself = true)
{
	if (!Element.prototype.matches)
	{
		Element.prototype.matches =
			Element.prototype.matchesSelector ||
			Element.prototype.mozMatchesSelector ||
			Element.prototype.msMatchesSelector ||
			Element.prototype.oMatchesSelector ||
			Element.prototype.webkitMatchesSelector ||
			function (s)
			{
				const matches = (this.document || this.ownerDocument).querySelectorAll(s);
				let i = matches.length;
				// while (--i >= 0 && matches.item(i) !== this)
				// {}
				return i > -1;
			};
	}

	if (searchItself === false && elem.parentNode)
	{
		elem = elem.parentNode;
	}

	for (; elem && elem !== document; elem = elem.parentNode)
	{
		if (elem.matches && elem.matches(selector))
		{
			return elem;
		}
	}

	return null;
};

export const boundHorizontally = function (element, containerElement)
{
	const elementRect = element.getBoundingClientRect();
	const containerElementRect = containerElement.getBoundingClientRect();

	return containerElementRect.left >= elementRect.right || containerElementRect.right <= elementRect.left;
}

export const boundVertically = function (element, containerElement)
{
	const elementRect = element.getBoundingClientRect();
	const containerElementRect = containerElement.getBoundingClientRect();

	return containerElementRect.top >= elementRect.bottom || containerElementRect.bottom <= elementRect.top;
}

export const localeNumber = function (value, decimals = 2, delimiter1 = " ", delimiter2 = ",")
{
	if (typeof value === "undefined" || value === null || (!value && value !== 0 && value !== "0"))
	{
		return localeNumber(0, decimals, delimiter1, delimiter2);
	}

	let suffix = "";
	if (typeof value === "string")
	{
		const v = parseFloat(value); // Will strip any existing suffix (like units)
		suffix = value.replace(v.toString(), "");
		value = v;
	}

	if (value === 0 || value === "0")
	{
		return value + suffix;
	}
	if (isNaN(value))
	{
		return localeNumber(0);
	}

	const d = Math.pow(10, decimals);
	value = Math.round(value * d) / d;

	const str1 = value.toString().split(".")[0].replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, delimiter1);
	const str2 = (decimals > 0 ? value.toFixed(decimals) : value.toString()).split(".")[1] || null;

	/**
	 * Build the output value. Don't show decimals unless they are not ,00
	 */
	let outputValue = str1;

	if (str2 && str2 !== "00")
	{
		outputValue += (str2 ? delimiter2 + str2 : "");
	}
	outputValue += suffix;

	return outputValue;
};

export const parseLocaleNumber = function (value, delimiter1 = " ", delimiter2 = ",")
{
	if (typeof value === "number")
	{
		return value;
	}
	if (!value)
	{
		return 0;
	}

	const re1 = new RegExp(delimiter1, "g");
	const re2 = new RegExp(delimiter2, "g");

	return parseFloat(value.replace(re1, "").replace(re2, "."));
};

export const formatSSN = (ssn, {withDash = true} = {}) =>
{
	// Remove any existing separators (such as dash or space)
	ssn = ssn.replace(/[-\s]/g, '');

	// Add prefix if needed and the separator at the appropriate positions
	if (ssn.length === 12 || ssn.length === 10)
	{
		if (ssn.length === 10)
		{
			// Format: YYMMDDNNNN
			const isCompany = (ssn[2] >= 2);

			// add prefix to ssn if missing
			ssn = isCompany ? "16" + ssn : "19" + ssn;
		}

		return withDash ? ssn.replace(/(\d{8})(\d{4})/, '$1-$2') : ssn;
	}

	return ssn;
};

export function decodeBase64Document(doc)
{
	let decodedDoc = "";

	if (doc.raw_data)
	{
		const base64str = doc.raw_data.substring(doc.raw_data.indexOf(',') + 1);
		decodedDoc = atob(base64str);
	}

	return decodedDoc;
}

export const getObjectKeyByValue = (object, value) =>
{
	return Object.keys(object).find(key => object[key] === value);
};

export const isDate = (year, month, day) =>
{
	year = parseInt(year, 10);
	month = parseInt(month, 10) - 1; // 0-11
	day = parseInt(day, 10);

	const tmpDate = new Date(year, month, day);
	const validYear = year === (y => y < 1000 ? y + 1900 : y)(tmpDate.getYear());
	const validMonth = month === tmpDate.getMonth();
	const validDay = day === tmpDate.getDate();

  return validYear && validMonth && validDay;
}

export const searchModules = (modules, component) => {
  for (let module of modules) {
    if (Array.isArray(module.widgets)) {
      for (let widget of module.widgets) {
        if ('inline' in widget) {
          for (let inlineWidget of widget.inline) {
            if (inlineWidget.component === component) {
                return true;
            }
          }
        }
        
        if (widget.component === component) {
          return true;
        }
      }
    }
  }
  return false;
}

export const swedishLocaleSettings = {
  months: [
      'januari', 'februari', 'mars', 'april', 'maj', 'juni',
      'juli', 'augusti', 'september', 'oktober', 'november', 'december'
  ],
  ordinal: (n) => {
    const v = n % 100;
    if (v === 1 || v === 2 || v === 21 || v === 22 || v === 31) {
        return `${n}:a`;
    }
    return `${n}:e`;
  }
};

export const checkIsLaptopOrDesktop = () => {
  return window.matchMedia("(min-width: 1050px)").matches;
};