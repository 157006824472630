import React from "react";
import PropTypes from "prop-types";

import Validator from "../../../../classes/Validator";
import TextField from "../../../../components/TextField/TextField";
import api from "../../../../helpers/api";
import ActivityIndicator from "../../../../components/ActivityIndicator/ActivityIndicator";
import fulfillment from "./LeiCodeField.fulfillment";

export default class LeiCodeField extends React.PureComponent
{
	static propTypes = {
		customer: PropTypes.object.isRequired,
		lookupInfo: PropTypes.object
	}

	state = _getInitialState.call(this);

	static getFields()
	{
		return [
			"customer_lei",
			"customer_lei_expiration"
		];
	}

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps === this.props)
		{
			return;
		}

		const {lookupInfo} = this.props;
		if (this.state.leiCode.length === 0 && lookupInfo)
		{
			const newState = {leiCode: lookupInfo.client.lei_code}
			if (this.state.leiCodeExpiration.length === 0)
			{
				newState.leiCodeExpiration = lookupInfo.client.lei_code_expiration;
			}

			if (lookupInfo.client.lei_code && lookupInfo.client.lei_code.length > 0)
			{
				this.setState(newState);
			}
		}
	}


	render()
	{
		return (
			<React.Fragment>
				<TextField
					name="customer_lei"
					label="LEI-kod (20 tecken)*"
					autocomplete="nope"
					value={this.state.leiCode}
					validation={Validator.validateLeiCode}
					onChange={({target}) => this._onLeiCodeChange(target.value)}
				/>
				<input type={"hidden"} name={"customer_lei_exp"} value={this.state.leiCodeExpiration}/>

				{this.state.leiCodeLoading && (
					<div className={"lei-loading"}>
						<ActivityIndicator color="#4cb7d5" busy/> Laddar...
					</div>
				)}

				{Validator.validateLeiCode(this.state.leiCode) &&
					!this.state.leiCodeLoading &&
					!this.state.leiExpired &&
					!this.state.leiError &&
					this.state.leiCodeExpiration !== "" && (
						<span style={{display: "block", padding: "7px 5px 2px 7px", fontSize: "12px"}}>
							LEI-kod giltig till
							<strong style={{color: "green"}}> {this.state.leiCodeExpiration}</strong>.
						</span>
					)}

				{!this.state.leiCodeLoading &&
					(Validator.validateLeiCode(this.state.leiCode) &&
					!this.state.leiExpired &&
						(this.state.leiError || this.state.leiCodeExpiration === "")) && (
						<span style={{display: "block", padding: "7px 5px 2px 7px", fontSize: "12px", color: "#999"}}>
							LEI-kodens giltighetstid kunde ej laddas.
						</span>
					)}

				{Validator.validateLeiCode(this.state.leiCode) &&
					!this.state.leiCodeLoading &&
					this.state.leiExpired && (
						<span style={{display: "block", padding: "7px 5px 2px 7px", fontSize: "12px"}}>
							LEI-kod ej giltig, gick ut
							<strong style={{color: "red"}}>	{this.state.leiCodeExpiration}</strong>.
						</span>
					)}

				{!Validator.validateLeiCode(this.state.leiCode) && (
					<span style={{display: "block", padding: "7px 5px 2px 7px", fontSize: "12px", color: this.state.leiCodeRequired ? "red" : "#999"}}>
						Giltig LEI-kod saknas.
					</span>
				)}
			</React.Fragment>
		);
	}

	_onLeiCodeChange = (value) =>
	{
		if (Validator.validateLeiCode(value))
		{
			this.setState({leiCode: value, leiCodeLoading: true}, async () =>
			{
				this.props.onUpdated(false);
				const result = await api("/lookup-lei", {lei: value});
				if (!result)
				{
					this.setState({
						leiCodeExpiration: "",
						leiCodeLoading: false,
						leiError: true,
						leiExpired: false
					}, () => {
						this.props.onUpdated(this.isFulfilled(this.state));
					});
				}
				else
				{
					const expiryDate = Date.parse(result.ExpireDate);
					this.setState({
						leiCodeExpiration: result.ExpireDate,
						leiCodeLoading: false,
						leiError: false,
						leiExpired: expiryDate < Date.now(),
					}, () => {
						this.props.onUpdated(this.isFulfilled(this.state));
					});
				}
			});
		}
		else
		{
			this.setState({leiCode: value, leiCodeLoading: false, leiExpired: false, leiError: false}, () => {
				this.props.onUpdated(this.isFulfilled(this.state));
			});
		}
	}

	static getViewInitialStateForCustomer(customer, lookupInfo, required)
	{
		const lookupLei = lookupInfo ? lookupInfo.client.lei_code : null;
		const lookupLeiExpiration = lookupInfo ? lookupInfo.client.lei_code_expiration : null;

		const leiCodeDate = customer.getData("customer_lei_exp") || lookupLeiExpiration || "";
		let leiExpired = false;
		if (leiCodeDate.length > 0)
		{
			leiExpired = Date.parse(leiCodeDate) < Date.now();
		}

		return {
			leiCode: customer.getData("customer_lei") || lookupLei || "",
			leiCodeExpiration: customer.getData("customer_lei_exp"),
			leiCodeLoading: false,
			leiCodeRequired: required,
			leiError: false,
			leiExpired: leiExpired
		};
	}
}

function _getInitialState()
{
	const {customer, lookupInfo, required} = this.props;
	return LeiCodeField.getViewInitialStateForCustomer(customer, lookupInfo, required);
}
