import React from "react";
import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import api from "@helpers/api";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import ContentBox from "@components/ContentBox/ContentBox";
import Validator from "@classes/Validator";
import OptionsList from "../OptionsList/OptionsList";
import Button from "@components/Button/Button";
import TextField from "@components/TextField/TextField";
import Select from '@components/Select/Select';

// HELPERS
import i18n from "@helpers/i18n";
import {formatSSN} from "@helpers/utils";

import IdentityNoInputField from "@cobComponents/IdentityNoInputField";

export default class Fullmaktskollen extends React.PureComponent
{
	static propTypes = {
		customerIdentityNumber: PropTypes.string.isRequired,
		user: PropTypes.object.isRequired
	}

	poaAppliesToOptions = [
		{
			label: "Samtliga anställda",
			value: "all",
		},
		{
			label: "Endast angivna anställda",
			value: "include",
		},
		{
			label: "Samtliga anställda med undantag för",
			value: "exclude",
		}
	];

	state = {
		customerIdentityNumber: "",
		isCompany: false,
		numberOfSignatories: 1,
		numberOfPoaPersons: 1,

		isLoadingData: false,
		isInputAreaExpanded: false,
		isSendingFmk: false,

		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		validToDate: "tillsvidare",
		validToDate_date: null,
		validToDate_text: "",

		poaAppliesTo: "",

		showSentFmkSuccess: false,
		showPoaRemoved: false,
		showRemovingOrReminding: false,
		showReminderSent: false,

		poaStatus: "not_sent",
		selectableAdvisors: null,
		selectedAdvisorIndex: -1
	}

	constructor(props)
	{
		super(props);
		// this.state.customerIdentityNumber = props.customerIdentityNumber;
		this.state.isCompany = Validator.validateOrgNum(props.customerIdentityNumber);
	}

	render()
	{
		const {state, props} = this;
		const {widget, inline, user, showHeader} = props;

		// Validation to determine whether the "Skicka fullmakt för signering" button should be enabled or not
		const isValidName = state.isCompany ? state.firstName.length > 0 : (state.firstName.length > 0 && state.lastName.length > 0);
		const isValidEmail = state.isCompany ? true : (state.email.length > 0 && Validator.validateEmail(state.email));
		const isValidPhone = state.isCompany ? true : (state.phone.length === 0 || (state.phone.length > 0 && Validator.validatePhoneForSmsSigning(state.phone)));
		const isValidDate = state.validToDate === "tillsvidare" || (state.validToDate === "fixed" && state.validToDate_date !== "" && state.validToDate_date > new Date());
		const isValidSelectedAdvisor = !user.hasPermission("fmk.choose_advisor") || (user.hasPermission("fmk.choose_advisor") && state.selectedAdvisorIndex > -1);
		const isValidPoaAppliesTo = state.isCompany ? (state.poaAppliesTo.length > 0) : true;

		const sendPoaToFmkButtonEnabled = isValidEmail &&
			isValidName &&
			isValidPhone &&
			isValidDate &&
			isValidSelectedAdvisor &&
			isValidPoaAppliesTo &&
			this._arePoaAppliesToPersonsValid() &&
			this._areSignatoriesValid();

		// Minimum POA validity date is 1 day ahead (86400000 ms)
		const minDatePickerDate = new Date();
		minDatePickerDate.setTime(minDatePickerDate.getTime() + 86400000);

		// If not used as a widget and invalid SSN we don't show this component
		if (!widget && !Validator.validateSsn(this.state.customerIdentityNumber) &&
			!Validator.validateOrgNum(this.state.customerIdentityNumber))
		{
			console.log("ERROR :: Can't show Fullmaktskollen component, invalid identity number")
			return null;
		}

		const componentContent = () =>
		{
			return (
				<div className="Cob-Fullmaktskollen">
					<React.Fragment>
						<IdentityNoInputField
							name="fmk_ssn"
							value={state.customerIdentityNumber}
							label={i18n("cob", state.isCompany ? "customers_organizational_number" : "customers_social_security_number")}
							placeholder="12-siffrigt utan bindestreck"
							onChangeCallback={this._onSsnOrgChange.bind(this)}
							readOnly={!widget}
							style={{margin: "10px 0px"}}
						/>
						<p className="Cob-miniNote">
							<strong>Exempel:</strong> <em>YYYYMMDDXXXX</em> eller <em>16XXXXXXXXXX</em>
						</p>
						{(state.isLoadingData) && (
							<div className="loading" style={{flexDirection: "row"}}>
								<ActivityIndicator color="#91a0ad" scale={0.75} busy/>
								<span style={{marginLeft: "10px"}}>Laddar...</span>
							</div>
						)}
						{!state.isLoadingData && (
							<>
								{state.poaStatus === 'signed' && (
									<span className={"status"}><strong>Informationsfullmakt</strong>: signerad</span>
								)}

								{state.showPoaRemoved && (
									<p className={"message"}>Fullmakten har tagits bort.</p>
								)}

								{state.poaStatus === 'not_sent' && state.isInputAreaExpanded && (
									<>
										<span className={"status"}>
											<strong>Informationsfullmakt</strong>: ej inhämtad
										</span>
										<TextField
											name="fmk_first_name"
											label={state.isCompany ? "Företagsnamn" : "Kundens förnamn"}
											value={state.firstName}
											validation={value => value.length > 0}
											onChange={({target}) => this.setState({
												poaStatus: "not_sent",
												firstName: target.value
											})}
											disabled={state.isLoadingData}
											style={{marginTop: "10px"}}
										/>

										{!state.isCompany && (
											<TextField
												name="fmk_last_name"
												label="Kundens efternamn"
												value={state.lastName}
												validation={value => value.length > 0}
												onChange={({target}) => this.setState({
													poaStatus: "not_sent",
													lastName: target.value
												})}
												disabled={state.isLoadingData}
												style={{marginTop: "10px"}}
											/>
										)}

										{!state.isCompany && (
											<React.Fragment>
												<p>
													Länk till fullmakten kommer att skickas med mail
													till epostadressen du anger nedan.
													Anges ett telefonnummer kommer kunden även att få
													ett SMS med länk.
												</p>

												<TextField
													name="fmk_email"
													label="Kundens epostadress"
													autocomplete="nope"
													value={state.email}
													onChange={({target}) => this.setState({
														poaStatus: "not_sent",
														email: target.value
													})}
													validation={Validator.validateEmail}
													style={{margin: "10px 0"}}
												/>

												<TextField
													name="fmk_phone"
													label="Kundens mobiltelefonnummer (valfritt)"
													autocomplete="nope"
													value={state.phone}
													onChange={({target}) => this.setState({
														poaStatus: "not_sent",
														phone: target.value
													})}
													placeholder={"+46XXXXXXXX"}
													validation={Validator.validatePhoneForSmsSigning}
													style={{margin: "10px 0 0 0"}}
												/>

												{state.phone.length >= 4 && state.phone.substr(0, 4) !== "+467" && (
													<p>
														Vänligen ange ett svenskt mobiltelefonnummer (inkl landskod
														+46).
													</p>
												)}
											</React.Fragment>
										)}

										{state.isCompany && (
											<React.Fragment>
												<p>
													Länk till fullmakten kommer att skickas med epost till personerna
													du anger nedan. Anges ett telefonnummer kommer personerna även att
													få ett SMS med länk.
												</p>

												<strong className={"header"}>
													Ange personer som ska signera fullmakten
												</strong>
												<ol className={"poa-signatories"}>
													{this._createSignatories()}
												</ol>

												<div className={"actions"}>
													<span onClick={() => this.setState({numberOfSignatories: (state.numberOfSignatories + 1)})}>Lägg till person</span>
													{state.numberOfSignatories > 1 && (
														<React.Fragment>
															| <span onClick={() => this.setState({numberOfSignatories: (state.numberOfSignatories - 1)})}>Ta bort senast tillagda</span>
														</React.Fragment>
													)}
												</div>

												<strong className={"header"}>Fullmakten gäller</strong>

												<Select
													style={{marginTop: "10px"}}
													name="poa_applies_to"
													label="Välj"
													options={this.poaAppliesToOptions}
													selectedIndex={this.poaAppliesToOptions.map(c => c.value).indexOf(this.state.poaAppliesTo)}
													onChange={({value}) => this.setState({poaAppliesTo: value})}
												/>

												{(state.poaAppliesTo === "include" || state.poaAppliesTo === "exclude") && (
													<React.Fragment>
														<ol className={"poa-signatories"}>
															{this._createPoaAppliesToPersons()}
														</ol>

														<div className={"actions"}>
															<span
																onClick={() => this.setState({numberOfPoaPersons: (state.numberOfPoaPersons + 1)})}>Lägg till person</span>
															{state.numberOfPoaPersons > 1 && (
																<React.Fragment>
																	| <span
																	onClick={() => this.setState({numberOfPoaPersons: (state.numberOfPoaPersons - 1)})}>Ta bort senast tillagda</span>
																</React.Fragment>
															)}
														</div>
													</React.Fragment>
												)}
											</React.Fragment>
										)}
										<strong className={"header"}>Giltighetstid</strong>

										<OptionsList
											style={{paddingBottom: "0", margin: "15px 0"}}
											type="radio"
											name="fmk_valid_to"
											options={[
												{
													label: "Tillsvidare",
													value: "tillsvidare",
													checked: state.validToDate === "tillsvidare"
												},
												{
													label: "Giltig t.o.m.",
													value: "fixed",
													checked: state.validToDate === "fixed"
												}
											]}
											onChange={(checked, validToDate) => this.setState({validToDate})}
										/>

										<DatePicker
											calendarStartDay={1}
											selected={state.validToDate === "tillsvidare" ? "" : state.validToDate_date}
											dateFormat="yyyy-MM-dd"
											minDate={minDatePickerDate}
											disabled={state.validToDate === "tillsvidare"}
											onChange={date => this.setState(
												{
													validToDate_date: date,
													validToDate_text: (new Intl.DateTimeFormat("sv-SE", {
														year: "numeric",
														month: "2-digit",
														day: "2-digit"
													}).format(date))
												})}/>

										{user.hasPermission("fmk.choose_advisor") && (
											<React.Fragment>
												<strong className={"header"}>Välj rådgivare</strong>

												<Select
													name="fmk-advisor"
													label="Välj"
													renderOption={this._renderAdvisorOption}
													options={state.selectableAdvisors}
													selectedIndex={state.selectedAdvisorIndex}
													onChange={({value}, index) => this.setState({selectedAdvisorIndex: index})}
												/>
											</React.Fragment>
										)}
										<Button
											className="Cob-Footer-button"
											label="Skicka fullmakt för signering"
											appearance={state.disabled ? "medium" : "secondary"}
											style={{marginTop: "20px"}}
											disabled={!sendPoaToFmkButtonEnabled}
											busy={state.isSendingFmk}
											onClick={this._sendPoaToFullmaktskollen}
											filled
										/>
									</>
								)}
							</>
						)}
					</React.Fragment>

					{!state.isLoadingData && state.poaStatus === 'sent' && (
						<React.Fragment>
							<span
								className={"status"}><strong>Informationsfullmakt</strong>: skickad, ej signerad</span>

							{(state.showRemovingOrReminding) && (
								<div className="loading" style={{marginTop: "30px"}}>
									<ActivityIndicator color="#91a0ad" scale={0.75} busy/>
								</div>
							)}

							{!state.showRemovingOrReminding && (
								<div className={"actions"}>
									<a href={"#/"} onClick={this.sendPoaReminder}>Påminn</a> |
									<a href={"#/"} onClick={this.removeSentPoa}>Ta bort</a>
								</div>
							)}

							{state.showSentFmkSuccess && (
								<p className={"message"}>Fullmakten har skickats till kund för signering.</p>
							)}

							{state.showReminderSent && (
								<p className={"message"}>Påminnelse har skickats till kund.</p>
							)}
						</React.Fragment>
					)}
				</div>
			);
		}

		return (
			widget ? (
					<ContentBox className={inline ? "inline" : ""}>
						<div className="Cob-view1-fields">
							{showHeader && <h2>Fullmaktskollen</h2>}
							<p className="Cob-description tightened">Ange kundens person-/organisationsnummer</p>
							{componentContent()}
						</div>
					</ContentBox>
				) :
				(
					<React.Fragment>
						<div className="h-line spacing"/>
						{componentContent()}
					</React.Fragment>
				)
		);
	}

	expandInputArea = state =>
	{
		this.setState({
			isInputAreaExpanded: state,
			showPoaRemoved: false,
			showSentFmkSuccess: false,
			showReminderSent: false
		}, async () =>
		{
			if (state)
			{
				await this._lookupBasicCustomerData(formatSSN(this.state.customerIdentityNumber, {withDash: true}));
			}
			else
			{
				this.setState({
					firstName: "",
					lastName: "",
					email: "",
					phone: ""
				});
			}
		});
	};

	_onSsnOrgChange(value, validated, valueCameFromPaste)
	{
		this.expandInputArea(false);
		if (validated)
		{
			const isCompanySsn = Validator.validateOrgNum(valueCameFromPaste ? formatSSN(value) : value);
			this.setState({
				customerIdentityNumber: valueCameFromPaste ? formatSSN(value) : value,
				isLoadingData: true
			}, async () =>
			{
				try
				{
					const poaInfo = await api("/fmk/status", {
						ssn: formatSSN(value),
						advisor: this.props.user.ssn
					});
					this.setState({
						isCompany: isCompanySsn,
						isLoadingData: false,
						isInputAreaExpanded: false,
						showSentFmkSuccess: false,
						showPoaRemoved: false,
						showRemovingOrReminding: false,
						showReminderSent: false,
						poaStatus: poaInfo.status,
						selectableAdvisors: poaInfo.advisors
					});

					if (poaInfo.status === "not_sent")
					{
						this.expandInputArea(true);
					}
				}
				catch (e)
				{
					console.error(e.message);
				}
			});
		}
		else
		{
			this.setState({
				customerIdentityNumber: value,
				isInputAreaExpanded: false,
				showSentFmkSuccess: false,
				showPoaRemoved: false,
				showRemovingOrReminding: false,
				showReminderSent: false,
				poaStatus: "",
				selectableAdvisors: []
			});
		}
	}

	sendPoaReminder = (event) =>
	{
		event.preventDefault();
		return new Promise(async (resolve) =>
		{
			this.setState({
				showRemovingOrReminding: true,
				showSentFmkSuccess: false,
				showReminderSent: false,
				showPoaRemoved: false
			}, async () =>
			{
				try
				{
					await api("/fmk/remind", {
						ssn: this.state.customerIdentityNumber,
						advisor: this.props.user.ssn
					});

					this.setState({
						showPoaRemoved: false,
						showReminderSent: true,
						showRemovingOrReminding: false
					}, () => resolve());
				}
				catch (e)
				{
					console.error(e.message);
					this.setState({
						showReminderSent: false,
						showPoaRemoved: false,
						showRemovingOrReminding: false
					}, () => resolve());
				}
			});
		});
	};

	removeSentPoa = (event) =>
	{
		event.preventDefault();
		return new Promise(async (resolve) =>
		{
			this.setState({
				showRemovingOrReminding: true,
				showSentFmkSuccess: false,
				showReminderSent: false,
				showPoaRemoved: false
			}, async () =>
			{
				try
				{
					await api("/fmk/remove", {
						ssn: this.state.customerIdentityNumber,
						advisor: this.props.user.ssn
					});

					this.setState({
						showPoaRemoved: true,
						showReminderSent: false,
						showRemovingOrReminding: false,
						isInputAreaExpanded: false,
						poaStatus: "not_sent"
					}, () => resolve());
				}
				catch (e)
				{
					console.error(e.message);
					this.setState({
						showReminderSent: false,
						showPoaRemoved: false,
						showRemovingOrReminding: false
					}, () => resolve());
				}
			});
		});
	};

	_sendPoaToFullmaktskollen = () =>
	{
		return new Promise((resolve) =>
		{
			this.setState({isSendingFmk: true}, async () =>
			{
				const {state, props} = this;

				try
				{
					let postValues = {
						isCompany: state.isCompany,
						partnerSsn: props.user.ssn,
						partnerEmail: props.user.email,
						partnerFirstName: props.user.firstName,
						partnerLastName: props.user.lastName,
						ssn: state.customerIdentityNumber,
						firstName: state.firstName,
						lastName: state.lastName,
						phone: state.phone,
						email: state.email,
						validTo: state.validToDate === "tillsvidare" ? "" : state.validToDate_text,
						appliesTo: state.poaAppliesTo,
						appliesToPersons: [],
						signatories: []
					};

					if (state.isCompany)
					{
						for (let i = 1; i <= state.numberOfSignatories; i++)
						{
							postValues.signatories.push({
								firstName: state['signatory_firstName_' + i],
								lastName: state['signatory_lastName_' + i],
								ssn: state['signatory_ssn_' + i],
								phone: state['signatory_phone_' + i],
								email: state['signatory_email_' + i],
							});
						}

						if (state.poaAppliesTo === "include" || state.poaAppliesTo === "exclude")
						{
							for (let i = 1; i <= state.numberOfPoaPersons; i++)
							{
								postValues.appliesToPersons.push({
									firstName: state['poa_applies_to_firstName_' + i],
									lastName: state['poa_applies_to_lastName_' + i],
									ssn: state['poa_applies_to_ssn_' + i]
								});
							}
						}
					}

					if (this.props.user.hasPermission("fmk.choose_advisor"))
					{
						postValues.advisor = state.selectableAdvisors[state.selectedAdvisorIndex].value;
					}

					await api("/fmk/create", postValues);

					this.setState({
						isSendingFmk: false,
						showSentFmkSuccess: true,
						showPoaRemoved: false,
						showRemovingOrReminding: false,
						showReminderSent: false,
						poaStatus: "sent"
					}, () => resolve());
				}
				catch (e)
				{
					console.error(e.message);
					this.setState({isSendingFmk: false}, () => resolve());
				}
			});
		});
	};

	_lookupBasicCustomerData(customerSsn)
	{
		return new Promise(async (resolve) =>
		{
			try
			{
				const lookupResult = await api("/lookup-simple", {
					ssn: customerSsn
				});

				this.setState({
					firstName: lookupResult.first_name,
					lastName: lookupResult.last_name,
					email: lookupResult.email,
					phone: lookupResult.phone
				}, () =>
				{
					this.setState({
						isLoadingData: false
					}, () => resolve());
				});
			}
			catch (e)
			{
				this.setState({
					isLoadingData: false
				}, () => resolve());
			}
		});
	}

	_renderAdvisorOption = ({personal_number, first_name, last_name}) =>
	{
		return (
			<div className="Cob-view1-caseOption">
				<div className="label">{first_name} {last_name}</div>
				<div className="info draft">{personal_number.substring(0, 8)}-XXXX</div>
			</div>
		);
	};

	_areSignatoriesValid = () =>
	{
		const {state} = this;
		if (!state.isCompany)
		{
			return true;
		}

		for (let i = 1; i <= state.numberOfSignatories; i++)
		{
			const isValidFirstName = state['signatory_firstName_' + i] !== undefined && state['signatory_firstName_' + i].length > 0;
			const isValidLastName = state['signatory_lastName_' + i] !== undefined && state['signatory_lastName_' + i].length > 0;
			const isValidEmail = state['signatory_email_' + i] !== undefined && Validator.validateEmail(state['signatory_email_' + i]);
			const isValidPhone = state['signatory_phone_' + i] === undefined || state['signatory_phone_' + i].length === 0 || Validator.validatePhoneForSmsSigning(state['signatory_phone_' + i]);
			const isValidSsn = state['signatory_ssn_' + i] !== undefined && Validator.validateSsn(state['signatory_ssn_' + i]);

			if (!isValidFirstName || !isValidLastName || !isValidEmail || !isValidPhone || !isValidSsn)
			{
				return false;
			}
		}

		return true;
	}

	_arePoaAppliesToPersonsValid = () =>
	{
		const {state} = this;
		if (!state.isCompany || state.poaAppliesTo === "" || state.poaAppliesTo === "all")
		{
			return true;
		}

		for (let i = 1; i <= state.numberOfPoaPersons; i++)
		{
			const isValidFirstName = state['poa_applies_to_firstName_' + i] !== undefined && state['poa_applies_to_firstName_' + i].length > 0;
			const isValidLastName = state['poa_applies_to_lastName_' + i] !== undefined && state['poa_applies_to_lastName_' + i].length > 0;
			const isValidSsn = state['poa_applies_to_ssn_' + i] !== undefined && Validator.validateSsn(state['poa_applies_to_ssn_' + i]);

			if (!isValidFirstName || !isValidLastName || !isValidSsn)
			{
				return false;
			}
		}

		return true;
	}

	_createSignatories = () =>
	{
		const list = [];
		const {state} = this;

		for (let i = 1; i <= state.numberOfSignatories; i++)
		{
			list.push(
				<li>
					<div className={"poa-signatory-row"}>
						<TextField name={"signatory_firstName_" + i}
						           label="Förnamn"
						           value={state['signatory_firstName_' + i]}
						           validation={value => value.length > 0}
						           onChange={({target}) => this.setState({['signatory_firstName_' + i]: target.value})}/>
						<TextField name={"signatory_lastName_" + i}
						           label="Efternamn"
						           value={state['signatory_lastName_' + i]}
						           validation={value => value.length > 0}
						           onChange={({target}) => this.setState({['signatory_lastName_' + i]: target.value})}/>
					</div>

					<div className={"poa-signatory-row"}>
						<TextField name={"signatory_ssn_" + i}
						           label="Personnummer"
						           value={state['signatory_ssn_' + i]}
						           validation={Validator.validateSsn}
						           onChange={({target}) => this.setState({['signatory_ssn_' + i]: target.value})}/>
						<TextField name={"signatory_phone_" + i}
						           label="Telefonnr (valfritt)"
						           value={state['signatory_phone_' + i]}
						           placeholder={"+46XXXXXXXX"}
						           validation={value => (value.length > 0 ? Validator.validatePhoneForSmsSigning(state['signatory_phone_' + i]) : undefined)}
						           onChange={({target}) => this.setState({['signatory_phone_' + i]: target.value})}/>
					</div>

					<TextField name={"signatory_email_" + i}
					           label="Epostadress"
					           style={{width: "75%"}}
					           value={state['signatory_email_' + i]}
					           validation={value => (value.length > 0 ? Validator.validateEmail(state['signatory_email_' + i]) : undefined)}
					           onChange={({target}) => this.setState({['signatory_email_' + i]: target.value})}/>
				</li>
			);
		}

		return list;
	}

	_createPoaAppliesToPersons = () =>
	{
		const list = [];
		const {state} = this;

		for (let i = 1; i <= state.numberOfPoaPersons; i++)
		{
			list.push(
				<li>
					<div className={"poa-signatory-row"}>
						<TextField name={"poa_applies_to_firstName_" + i}
						           label="Förnamn"
						           value={state['poa_applies_to_firstName_' + i]}
						           validation={value => value.length > 0}
						           onChange={({target}) => this.setState({['poa_applies_to_firstName_' + i]: target.value})}/>
						<TextField name={"poa_applies_to_lastName_" + i}
						           label="Efternamn"
						           value={state['poa_applies_to_lastName_' + i]}
						           validation={value => value.length > 0}
						           onChange={({target}) => this.setState({['poa_applies_to_lastName_' + i]: target.value})}/>
					</div>

					<div className={"poa-signatory-row"}>
						<TextField name={"poa_applies_to_ssn_" + i}
						           label="Personnummer"
						           value={state['poa_applies_to_ssn_' + i]}
						           validation={Validator.validateSsn}
						           onChange={({target}) => this.setState({['poa_applies_to_ssn_' + i]: target.value})}/>
					</div>
				</li>
			);
		}

		return list;
	}
}