import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import Table from "@components/Table/Table";
import TableSelect from "../../../components/TableSelect/TableSelect";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";

// OTHER
import fulfillment from "./PreviousExperience.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import TextView from "../../../../../components/TextView/TextView";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Select from "../../../../../components/Select/Select";
import TextField from "../../../../../components/TextField/TextField";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";

export default class PreviousExperience extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {state, props} = this;
		const {customer} = props;
		const {customerState, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view5", {});

		return (
			<div className={classes} style={{width: "100% !important"}}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Lämplighetsbedömning</h2>

					<div className="Cob-view5-fields">
						{customer.isCompany && (
							<React.Fragment>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<TextField
											name="date"
											value={customerState.date}
											autocomplete="nope"
											label="Datum för rådgivningen"
											validation={value => value.length > 0}
											onChange={({target}) => this.setCustomerState({date: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<Select
											label="Rådgivningens utförande"
											selectedIndex={customerState.meetingFormatIndex}
											options={[
												{label: "Personligt möte", value: "personal"},
												{label: "Telefonmöte", value: "phone"},
												{label: "Videomöte", value: "video"}
											]}
											onChange={(option, meetingFormatIndex) => this.setCustomerState({
												meetingFormat: option.value,
												meetingFormatIndex
											})}
											highlightErrors={hasFormValidationErrors}
										/>

										<input type={"hidden"} name="radio_meeting_type"
											   value={customerState.meetingFormat == null ? "" : customerState.meetingFormat}/>
									</div>
								</StackLayout>

								{(customerState.meetingFormat === "phone" || customerState.meetingFormat === "video") && (
									<React.Fragment>
										<strong>Vid rådgivning på distans (samtliga ska uppfyllas)</strong>

										<div style={{flexDirection: "row", marginBottom: "30px", marginTop: "5px"}}>
											<Checkbox
												name={"checkbox_customer_extcheck"}
												label={"Kontroll mot externa register"}
												checked={customerState.checkbox_customer_extcheck === "yes"}
												value={"yes"}
												highlightErrors={hasFormValidationErrors}
												onChange={checked => this.setCustomerState({checkbox_customer_extcheck: checked ? "yes" : "no"})}
											/>
											<Checkbox
												name={"checkbox_customer_countercheck"}
												label={"Utfört motkontroll"}
												checked={customerState.checkbox_customer_countercheck === "yes"}
												value={"yes"}
												highlightErrors={hasFormValidationErrors}
												onChange={checked => this.setCustomerState({checkbox_customer_countercheck: checked ? "yes" : "no"})}
												style={{marginLeft: "40px"}}
											/>
										</div>
									</React.Fragment>
								)}

								<h3 style={{paddingBottom: "10px"}}>Identitetskontroll</h3>
								<OptionsList
									type="checkbox"
									description="Rådgivaren har tagit del av"
									orientation="horizontal"
									style={{marginBottom: "20px"}}
									value={
										customerState.checkbox_idcheck_id === "yes" ||
										customerState.checkbox_idcheck_drivinglic === "yes" ||
										customerState.checkbox_idcheck_passport === "yes" ||
										customerState.checkbox_idcheck_known === "Ja" ?
											"filled" :
											""
									}
									options={[
										{
											name: "checkbox_idcheck_id",
											label: "ID-kort",
											value: customerState.checkbox_idcheck_id === "yes" ? "yes" : "no",
											checked: customerState.checkbox_idcheck_id === "yes",
											onChange: checked => this.setCustomerState({checkbox_idcheck_id: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_idcheck_drivinglic",
											label: "Körkort",
											value: customerState.checkbox_idcheck_drivinglic === "yes" ? "yes" : "no",
											checked: customerState.checkbox_idcheck_drivinglic === "yes",
											onChange: checked => this.setCustomerState({checkbox_idcheck_drivinglic: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_idcheck_passport",
											label: "Pass",
											value: customerState.checkbox_idcheck_passport === "yes" ? "yes" : "no",
											checked: customerState.checkbox_idcheck_passport === "yes",
											onChange: checked => this.setCustomerState({checkbox_idcheck_passport: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_idcheck_known",
											label: "Känd",
											value: customerState.checkbox_idcheck_known === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_idcheck_known === "Ja",
											onChange: checked => this.setCustomerState({checkbox_idcheck_known: checked ? "Ja" : "no"})
										}
									]}
									validation={value => (value && value === "filled") || false}
									highlightErrors={hasFormValidationErrors}
								/>

								<div className="h-line spacing"/>
							</React.Fragment>
						)}

						<h3 style={{paddingBottom: "5px"}}>Kunskapsfrågor</h3>

						<p style={{marginTop: "0"}}>
							Beroende på svar kommer kunden hamna i någon av följande kategorier: grundläggande, informerad och avancerad.
						</p>

						<OptionsList
							type="radio"
							name="checkbox_customer_kyc_horisont"
							description={"En investerings placeringshorisont, alltså hur lång tid kunden tänker spara, påverkar det den risk som kunden kan ta med sin investering?"}
							value={customerState.checkbox_customer_kyc_horisont}
							style={{marginTop: "20px"}}
							options={[
								{
									label: i18n("general", "no"),
									value: "Nej",
									checked: customerState.checkbox_customer_kyc_horisont === "Nej"
								},
								{
									label: i18n("general", "yes"),
									value: "Ja",
									checked: customerState.checkbox_customer_kyc_horisont === "Ja"
								}
							]}
							onChange={(checked, checkbox_customer_kyc_horisont) => this.setCustomerState({checkbox_customer_kyc_horisont})}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
						/>

						<OptionsList
							type="radio"
							description="Hur mycket bör kunden vara beredd att förlora om kunden har en risk 1 på en riskskala mellan 1 och 7?"
							name="checkbox_customer_kyc_loss_1"
							orientation="horizontal"
							style={{marginTop: "20px"}}
							value={customerState.checkbox_customer_kyc_loss_1}
							options={[
								{
									label: "0-10%",
									value: "0-10",
									checked: customerState.checkbox_customer_kyc_loss_1 === "0-10",
								},
								{
									label: "11-50%",
									value: "11-50",
									checked: customerState.checkbox_customer_kyc_loss_1 === "11-50",
								},
								{
									label: "51-100%",
									value: "51-100",
									checked: customerState.checkbox_customer_kyc_loss_1 === "51-100"
								}
							]}
							onChange={(checked, checkbox_customer_kyc_loss_1) => this.setCustomerState({checkbox_customer_kyc_loss_1})}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
						/>

						<OptionsList
							type="radio"
							name="checkbox_customer_kyc_loss_7"
							style={{marginTop: "20px"}}
							description={"Hur mycket bör kunden vara beredd att förlora om kunden har en risk 7 på en riskskala mellan 1 och 7?"}
							value={customerState.checkbox_customer_kyc_loss_7}
							options={[
								{
									label: "0-10%",
									value: "0-10",
									checked: customerState.checkbox_customer_kyc_loss_7 === "0-10",
								},
								{
									label: "11-50%",
									value: "11-50",
									checked: customerState.checkbox_customer_kyc_loss_7 === "11-50",
								},
								{
									label: "51-100%",
									value: "51-100",
									checked: customerState.checkbox_customer_kyc_loss_7 === "51-100"
								}
							]}
							onChange={(checked, checkbox_customer_kyc_loss_7) => {
								console.log(checkbox_customer_kyc_loss_7);
								this.setCustomerState({checkbox_customer_kyc_loss_7});
							}}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
						/>

						<OptionsList
							type="radio"
							name="checkbox_customer_kyc_avoid"
							orientation="vertical"
							style={{marginTop: "20px"}}
							description={"Vad bör kunden göra för att vara säker på att inte förlora något kapital på en investering?"}
							value={customerState.checkbox_customer_kyc_avoid}
							options={[
								{
									label: "Enbart investera i lågriskprodukter",
									value: "lowrisk",
									checked: customerState.checkbox_customer_kyc_avoid === "lowrisk"
								},
								{
									label: "Avstå helt från att investera i finansiella instrument",
									value: "avoid",
									checked: customerState.checkbox_customer_kyc_avoid === "avoid"
								},
								{
									label: "Investera med en lång placeringshorisont",
									value: "long",
									checked: customerState.checkbox_customer_kyc_avoid === "long"
								}
							]}
							onChange={(checked, checkbox_customer_kyc_avoid) => this.setCustomerState({checkbox_customer_kyc_avoid})}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
						/>

						<div className="h-line spacing"/>

						<h4 style={{width: "100%", padding: "0 0 5px 0"}}>
							Kunskap och erfarenhet om finansiella tjänster och försäkringar
						</h4>

						<Table
							className="Cob-Table"
							columns={_getTableColumnsServices.call(this)}
							rows={_getTableRowsServices.call(this)}
							validation={this.validateTableServices()}
							highlightErrors={hasFormValidationErrors}
						/>

						<h4 style={{width: "100%", padding: "20px 0 5px 0"}}>
							Kunskap och erfarenhet om värdepapper och fonder
						</h4>

						<table className="definitions">
							<tbody>
							<tr>
								<td colSpan="2" className="header">Definitioner</td>
							</tr>
							<tr>
								<td width="15%" className="label">Ingen:</td>
								<td width="85%">Du har aldrig hört talas om det finansiella instrumentet, och/eller har
									absolut ingen förståelse för hur det fungerar.
								</td>
							</tr>
							<tr>
								<td width="15%" className="label">Någon:</td>
								<td width="85%">Du har hört talas om det finansiella instrumentet men förstår inte hur
									det fungerar.
								</td>
							</tr>
							<tr>
								<td width="15%" className="label">Medel:</td>
								<td width="85%">Du känner till det finansiella instrumentet och förstår hur det
									fungerar.
								</td>
							</tr>
							<tr>
								<td width="15%" className="label">Hög:</td>
								<td width="85%">Du känner väl till det finansiella instrumentet och har god förståelse
									för hur det fungerar.
								</td>
							</tr>
							</tbody>
						</table>

						<Table
							className="Cob-Table"
							columns={_getTableColumns.call(this)}
							rows={_getTableRows.call(this)}
							validation={this.validateTableMain()}
							highlightErrors={hasFormValidationErrors}
						/>

						<div className="h-line spacing"/>

						<OptionsList
							type="radio"
							name="checkbox_kyc_educated"
							description={"Har kunden utbildats?"}
							value={customerState.checkbox_kyc_educated}
							style={{marginTop: "0", marginBottom: "5px"}}
							options={[
								{
									label: i18n("general", "no"),
									value: "Nej",
									checked: customerState.checkbox_kyc_educated === "Nej"
								},
								{
									label: i18n("general", "yes"),
									value: "Ja",
									checked: customerState.checkbox_kyc_educated === "Ja"
								}
							]}
							onChange={(checked, checkbox_kyc_educated) => this.setCustomerState({checkbox_kyc_educated})}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
						/>
						<p style={{marginTop: "0"}}>
							Vilken genomgång har gjorts och vad har diskuterats? <em>T.ex. är kunden medveten om begreppet
							likviditetsrisk och förstår denne konceptet med risken att inte alltid kunna omsätta
							investeringar till kontanter snabbt?</em>
						</p>
						<TextView
							name="advice_notes2"
							label={`${i18n("general", "notes")} (${1500 - customerState.advice_notes2.length} tecken kvar)`}
							value={customerState.advice_notes2}
							maxLength={1500}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_notes2: target.value})}
							fullWidth
							minHeight={200}
							highlightErrors={customerState.checkbox_kyc_educated === "Ja" && hasFormValidationErrors}
						/>

						<Table
							className="Cob-Table second"
							columns={_getTableColumnsUnderstands.call(this)}
							rows={_getTableRowsUnderstands.call(this)}
							validation={this.validateTableUnderstands()}
							highlightErrors={hasFormValidationErrors}
						/>


					</div>
				</ContentBox>
			</div>
		);
	}

	_optionsValidation = (value) => ((value && value.length > 0) || false);

	setTableData(fields, callbackFn)
	{
		this.setCustomerState({tableData: {...this.state.customerState.tableData, ...fields}}, callbackFn);
	}

	validateTableMain()
	{
		for (const [key, value] of Object.entries(this.state.customerState.tableData))
		{
			if ((
					key === "radio_kyc_transactions_aktier" ||
					key === "radio_kyc_knowledge_aktier" ||
					key === "radio_aml_transaction_size_aktier" ||
					key === "radio_kyc_transactions_onot_aktier" ||
					key === "radio_kyc_knowledge_onot_aktier" ||
					key === "radio_aml_transaction_size_onot_aktier" ||
					key === "radio_kyc_transactions_fonder" ||
					key === "radio_kyc_knowledge_fonder" ||
					key === "radio_aml_transaction_size_fonder" ||
					key === "radio_kyc_transactions_aio" ||
					key === "radio_kyc_knowledge_aio" ||
					key === "radio_aml_transaction_size_aio" ||
					key === "radio_kyc_transactions_autocalls" ||
					key === "radio_kyc_knowledge_autocalls" ||
					key === "radio_aml_transaction_size_autocalls" ||
					key === "radio_kyc_transactions_derivat" ||
					key === "radio_kyc_knowledge_derivat" ||
					key === "radio_aml_transaction_size_derivat" ||
					key === "radio_kyc_transactions_index_products" ||
					key === "radio_kyc_knowledge_index_products" ||
					key === "radio_aml_transaction_size_index_products"
				)
				&& value.length <= 0)
			{
				return false;
			}
		}
		return true;
	}

	validateTableUnderstands()
	{
		for (const [key, value] of Object.entries(this.state.customerState.tableData))
		{
			if ((
					key === "radio_understands_aktier" ||
					key === "radio_understands_onot_aktier" ||
					key === "radio_understands_fonder" ||
					key === "radio_understands_aio" ||
					key === "radio_understands_autocalls" ||
					key === "radio_understands_derivat" ||
					key === "radio_understands_index_products"
				)
				&& value.length <= 0)
			{
				return false;
			}
		}
		return true;
	}

	validateTableServices()
	{
		for (const [key, value] of Object.entries(this.state.customerState.tableData))
		{
			if ((
					key === "radio_understands_depa" ||
					key === "radio_understands_isk" ||
					key === "radio_understands_tpk" ||
					key === "radio_understands_investment" ||
					key === "radio_education_depa" ||
					key === "radio_education_isk" ||
					key === "radio_education_tpk" ||
					key === "radio_education_investment" ||
					key === "radio_experience_depa" ||
					key === "radio_experience_isk" ||
					key === "radio_experience_tpk" ||
					key === "radio_experience_investment"
				)
				&& value.length <= 0)
			{
				return false;
			}
		}
		return true;
	}

	_getTableData = (fieldName, newState) =>
	{
		const {tableData} = this.state.customerState;
		const td = {...tableData, ...newState};
		return td[fieldName] ? parseFloat(td[fieldName], 10) : 0;
	};
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	const newState = {
		customerState: {
			tableData: customer.view5TableData,

			advice_notes2: customer.getData("advice_notes2"),

			checkbox_customer_extcheck: customer.getData("checkbox_customer_extcheck", ""),
			checkbox_customer_countercheck: customer.getData("checkbox_customer_countercheck", ""),

			aktier_not_applicable: customer.getData("aktier_not_applicable"),
			onot_aktier_not_applicable: customer.getData("onot_aktier_not_applicable"),
			fonder_not_applicable: customer.getData("fonder_not_applicable"),
			aio_not_applicable: customer.getData("aio_not_applicable"),
			autocalls_not_applicable: customer.getData("autocalls_not_applicable"),
			derivative_not_applicable: customer.getData("derivative_not_applicable"),
			index_products_not_applicable: customer.getData("index_products_not_applicable"),

			understandsDepa: (
				customer.getData("radio_understands_depa") === "yes" ? 0 :
					customer.getData("radio_understands_depa") === "no" ? 1 :
						-1
			),

			understandsIsk: (
				customer.getData("radio_understands_isk") === "yes" ? 0 :
					customer.getData("radio_understands_isk") === "no" ? 1 :
						-1
			),

			understandsTpk: (
				customer.getData("radio_understands_tpk") === "yes" ? 0 :
					customer.getData("radio_understands_tpk") === "no" ? 1 :
						-1
			),

			understandsInvestment: (
				customer.getData("radio_understands_investment") === "yes" ? 0 :
					customer.getData("radio_understands_investment") === "no" ? 1 :
						-1
			),


			experienceDepa: (
				customer.getData("radio_experience_depa") === "yes" ? 0 :
					customer.getData("radio_experience_depa") === "no" ? 1 :
						-1
			),

			experienceIsk: (
				customer.getData("radio_experience_isk") === "yes" ? 0 :
					customer.getData("radio_experience_isk") === "no" ? 1 :
						-1
			),

			experienceTpk: (
				customer.getData("radio_experience_tpk") === "yes" ? 0 :
					customer.getData("radio_experience_tpk") === "no" ? 1 :
						-1
			),

			experienceInvestment: (
				customer.getData("radio_experience_investment") === "yes" ? 0 :
					customer.getData("radio_experience_investment") === "no" ? 1 :
						-1
			),


			educationDepa: (
				customer.getData("radio_education_depa") === "yes" ? 0 :
					customer.getData("radio_education_depa") === "no" ? 1 :
						-1
			),

			educationIsk: (
				customer.getData("radio_education_isk") === "yes" ? 0 :
					customer.getData("radio_education_isk") === "no" ? 1 :
						-1
			),

			educationTpk: (
				customer.getData("radio_education_tpk") === "yes" ? 0 :
					customer.getData("radio_education_tpk") === "no" ? 1 :
						-1
			),

			educationInvestment: (
				customer.getData("radio_education_investment") === "yes" ? 0 :
					customer.getData("radio_education_investment") === "no" ? 1 :
						-1
			),

			// TableSelect
			understandsAktier: (
				customer.getData("radio_understands_aktier") === "yes" ? 0 :
					customer.getData("radio_understands_aktier") === "no" ? 1 :
						-1
			),

			understandsOnoterat: (
				customer.getData("radio_understands_onot_aktier") === "yes" ? 0 :
					customer.getData("radio_understands_onot_aktier") === "no" ? 1 :
						-1
			),

			understandsFonder: (
				customer.getData("radio_understands_fonder") === "yes" ? 0 :
					customer.getData("radio_understands_fonder") === "no" ? 1 :
						-1
			),

			understandsAio: (
				customer.getData("radio_understands_aio") === "yes" ? 0 :
					customer.getData("radio_understands_aio") === "no" ? 1 :
						-1
			),

			understandsAutocalls: (
				customer.getData("radio_understands_autocalls") === "yes" ? 0 :
					customer.getData("radio_understands_autocalls") === "no" ? 1 :
						-1
			),

			understandsDerivat: (
				customer.getData("radio_understands_derivat") === "yes" ? 0 :
					customer.getData("radio_understands_derivat") === "no" ? 1 :
						-1
			),

			understandsIndexProducts: (
				customer.getData("radio_understands_index_products") === "yes" ? 0 :
					customer.getData("radio_understands_index_products") === "no" ? 1 :
						-1
			),

			aktierKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_aktier") === "ingen" ? 0 :
					customer.getData("radio_kyc_knowledge_aktier") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_aktier") === "medel" ? 2 :
							customer.getData("radio_kyc_knowledge_aktier") === "hog" ? 3 :
								-1
			),
			aktierTransactionsIndex: (
				customer.getData("radio_kyc_transactions_aktier") === "0-5" ? 0 :
					customer.getData("radio_kyc_transactions_aktier") === "6-15" ? 1 :
						customer.getData("radio_kyc_transactions_aktier") === "16-50" ? 2 :
							customer.getData("radio_kyc_transactions_aktier") === ">50" ? 3 :
								-1
			),
			aktierEducationIndex: (
				customer.getData("radio_aml_transaction_size_aktier") === "0-100" ? 0 :
					customer.getData("radio_aml_transaction_size_aktier") === "101-500" ? 1 :
						customer.getData("radio_aml_transaction_size_aktier") === "501-1500" ? 2 :
							customer.getData("radio_aml_transaction_size_aktier") === ">1500" ? 3 :
								-1
			),

			onoteratKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_onot_aktier") === "ingen" ? 0 :
					customer.getData("radio_kyc_knowledge_onot_aktier") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_onot_aktier") === "medel" ? 2 :
							customer.getData("radio_kyc_knowledge_onot_aktier") === "hog" ? 3 :
								-1
			),
			onoteratTransactionsIndex: (
				customer.getData("radio_kyc_transactions_onot_aktier") === "0-5" ? 0 :
					customer.getData("radio_kyc_transactions_onot_aktier") === "6-15" ? 1 :
						customer.getData("radio_kyc_transactions_onot_aktier") === "16-50" ? 2 :
							customer.getData("radio_kyc_transactions_onot_aktier") === ">50" ? 3 :
								-1
			),
			onoteratEducationIndex: (
				customer.getData("radio_aml_transaction_size_onot_aktier") === "0-100" ? 0 :
					customer.getData("radio_aml_transaction_size_onot_aktier") === "101-500" ? 1 :
						customer.getData("radio_aml_transaction_size_onot_aktier") === "501-1500" ? 2 :
							customer.getData("radio_aml_transaction_size_onot_aktier") === ">1500" ? 3 :
								-1
			),

			fundsKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_fonder") === "ingen" ? 0 :
					customer.getData("radio_kyc_knowledge_fonder") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_fonder") === "medel" ? 2 :
							customer.getData("radio_kyc_knowledge_fonder") === "hog" ? 3 :
								-1
			),
			fonderTransactionsIndex: (
				customer.getData("radio_kyc_transactions_fonder") === "0-5" ? 0 :
					customer.getData("radio_kyc_transactions_fonder") === "6-15" ? 1 :
						customer.getData("radio_kyc_transactions_fonder") === "16-50" ? 2 :
							customer.getData("radio_kyc_transactions_fonder") === ">50" ? 3 :
								-1
			),
			fonderEducationIndex: (
				customer.getData("radio_aml_transaction_size_fonder") === "0-100" ? 0 :
					customer.getData("radio_aml_transaction_size_fonder") === "101-500" ? 1 :
						customer.getData("radio_aml_transaction_size_fonder") === "501-1500" ? 2 :
							customer.getData("radio_aml_transaction_size_fonder") === ">1500" ? 3 :
								-1
			),

			aioKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_aio") === "ingen" ? 0 :
					customer.getData("radio_kyc_knowledge_aio") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_aio") === "medel" ? 2 :
							customer.getData("radio_kyc_knowledge_aio") === "hog" ? 3 :
								-1
			),
			aioTransactionsIndex: (
				customer.getData("radio_kyc_transactions_aio") === "0-5" ? 0 :
					customer.getData("radio_kyc_transactions_aio") === "6-15" ? 1 :
						customer.getData("radio_kyc_transactions_aio") === "16-50" ? 2 :
							customer.getData("radio_kyc_transactions_aio") === ">50" ? 3 :
								-1
			),
			aioEducationIndex: (
				customer.getData("radio_aml_transaction_size_aio") === "0-100" ? 0 :
					customer.getData("radio_aml_transaction_size_aio") === "101-500" ? 1 :
						customer.getData("radio_aml_transaction_size_aio") === "501-1500" ? 2 :
							customer.getData("radio_aml_transaction_size_aio") === ">1500" ? 3 :
								-1
			),

			autocallsKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_autocalls") === "ingen" ? 0 :
					customer.getData("radio_kyc_knowledge_autocalls") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_autocalls") === "medel" ? 2 :
							customer.getData("radio_kyc_knowledge_autocalls") === "hog" ? 3 :
								-1
			),
			autocallsTransactionsIndex: (
				customer.getData("radio_kyc_transactions_autocalls") === "0-5" ? 0 :
					customer.getData("radio_kyc_transactions_autocalls") === "6-15" ? 1 :
						customer.getData("radio_kyc_transactions_autocalls") === "16-50" ? 2 :
							customer.getData("radio_kyc_transactions_autocalls") === ">50" ? 3 :
								-1
			),
			autocallsEducationIndex: (
				customer.getData("radio_aml_transaction_size_autocalls") === "0-100" ? 0 :
					customer.getData("radio_aml_transaction_size_autocalls") === "101-500" ? 1 :
						customer.getData("radio_aml_transaction_size_autocalls") === "501-1500" ? 2 :
							customer.getData("radio_aml_transaction_size_autocalls") === ">1500" ? 3 :
								-1
			),

			derivativeKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_derivat") === "ingen" ? 0 :
					customer.getData("radio_kyc_knowledge_derivat") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_derivat") === "medel" ? 2 :
							customer.getData("radio_kyc_knowledge_derivat") === "hog" ? 3 :
								-1
			),
			derivativeTransactionsIndex: (
				customer.getData("radio_kyc_transactions_derivat") === "0-5" ? 0 :
					customer.getData("radio_kyc_transactions_derivat") === "6-15" ? 1 :
						customer.getData("radio_kyc_transactions_derivat") === "16-50" ? 2 :
							customer.getData("radio_kyc_transactions_derivat") === ">50" ? 3 :
								-1
			),
			derivativeEducationIndex: (
				customer.getData("radio_aml_transaction_size_derivat") === "0-100" ? 0 :
					customer.getData("radio_aml_transaction_size_derivat") === "101-500" ? 1 :
						customer.getData("radio_aml_transaction_size_derivat") === "501-1500" ? 2 :
							customer.getData("radio_aml_transaction_size_derivat") === ">1500" ? 3 :
								-1
			),

			indexProductsKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_index_products") === "ingen" ? 0 :
					customer.getData("radio_kyc_knowledge_index_products") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_index_products") === "medel" ? 2 :
							customer.getData("radio_kyc_knowledge_index_products") === "hog" ? 3 :
								-1
			),
			indexProductsTransactionsIndex: (
				customer.getData("radio_kyc_transactions_index_products") === "0-5" ? 0 :
					customer.getData("radio_kyc_transactions_index_products") === "6-15" ? 1 :
						customer.getData("radio_kyc_transactions_index_products") === "16-50" ? 2 :
							customer.getData("radio_kyc_transactions_index_products") === ">50" ? 3 :
								-1
			),
			indexProductsEducationIndex: (
				customer.getData("radio_aml_transaction_size_index_products") === "0-100" ? 0 :
					customer.getData("radio_aml_transaction_size_index_products") === "101-500" ? 1 :
						customer.getData("radio_aml_transaction_size_index_products") === "501-1500" ? 2 :
							customer.getData("radio_aml_transaction_size_index_products") === ">1500" ? 3 :
								-1
			),

			checkbox_customer_kyc_loss_1: customer.getData("checkbox_customer_kyc_loss_1") || "",
			checkbox_customer_kyc_loss_7: customer.getData("checkbox_customer_kyc_loss_7") || "",

			checkbox_kyc_educated: customer.getData("checkbox_kyc_educated") || "",
			checkbox_customer_kyc_horisont: customer.getData("checkbox_customer_kyc_horisont") || "",
			checkbox_customer_kyc_avoid: customer.getData("checkbox_customer_kyc_avoid") || "",

			customer_occupation_selectIndex: -1,
			customer_occupation_select: "",

			isCompany: customer.isCompany,
			date: customer.getData("date") || "",
			meetingFormat: customer.getData("radio_meeting_type", null),

			meetingFormatIndex: (
				customer.getData("radio_meeting_type") === "personal" ? 0 :
					customer.getData("radio_meeting_type") === "phone" ? 1 :
						customer.getData("radio_meeting_type") === "video" ? 2 :
							-1),

			checkbox_idcheck_id: customer.getData("checkbox_idcheck_id") ? "yes" : "no",
			checkbox_idcheck_drivinglic: customer.getData("checkbox_idcheck_drivinglic") ? "yes" : "no",
			checkbox_idcheck_passport: customer.getData("checkbox_idcheck_passport") ? "yes" : "no",
			checkbox_idcheck_known: customer.getData("checkbox_idcheck_known") ? "Ja" : "no"
		}
	};

	if (newState.customerState.date === "")
	{
		const today = new Date();
		let dayDate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
		let monthDate = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		newState.customerState.date = today.getFullYear() + '-' + monthDate + '-' + dayDate;
	}

	newState.isFulfilled = false;
	return newState;
}

function _getFields()
{
	return [
		...Object.keys(this.state.customerState.tableData),
		"advice_notes2",
		"checkbox_kyc_educated",
		"checkbox_customer_kyc_loss_1",
		"checkbox_customer_kyc_loss_7"
	];
}

function _getTableColumns()
{
	return [
		{
			key: "investment",
			label: "",
			width: "31%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "knowledge",
			label: "Hur stor kunskap har kunden om finansiella instrument och produkter?",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "amountOfbusinessLast3Years",
			label: "Hur ofta gör kunden affärer?",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "education",
			label: "Hur stor är genomsnittsaffären i kronor (tusental)?",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		}
	];
}

function _getTableRows()
{
	return [
		_createTableRow.call(this, {
			investment: {
				name: "radio_kyc_transactions_aktier",
				label: "Aktier",
				indexName: "aktierTransactionsIndex",
				checkboxName: "aktier_not_applicable"
			},
			knowledge: {name: "radio_kyc_knowledge_aktier", indexName: "aktierKnowledgeIndex"},
			education: {name: "radio_aml_transaction_size_aktier", indexName: "aktierEducationIndex"},
		}),

		_createTableRow.call(this, {
			investment: {
				name: "radio_kyc_transactions_onot_aktier",
				label: "Onoterade aktier",
				indexName: "onoteratTransactionsIndex",
				checkboxName: "onot_aktier_not_applicable"
			},
			knowledge: {name: "radio_kyc_knowledge_onot_aktier", indexName: "onoteratKnowledgeIndex"},
			education: {name: "radio_aml_transaction_size_onot_aktier", indexName: "onoteratEducationIndex"},
		}),

		_createTableRow.call(this, {
			investment: {
				name: "radio_kyc_transactions_fonder",
				label: "Investeringsfonder, ETF:er",
				indexName: "fonderTransactionsIndex",
				checkboxName: "fonder_not_applicable"
			},
			knowledge: {name: "radio_kyc_knowledge_fonder", indexName: "fundsKnowledgeIndex"},
			education: {name: "radio_aml_transaction_size_fonder", indexName: "fonderEducationIndex"},
		}),

		_createTableRow.call(this, {
			investment: {
				name: "radio_kyc_transactions_aio",
				label: "Kapitalskyddade produkter, t.ex. Aktieindexobligationer",
				indexName: "aioTransactionsIndex",
				checkboxName: "aio_not_applicable"
			},
			knowledge: {name: "radio_kyc_knowledge_aio", indexName: "aioKnowledgeIndex"},
			education: {name: "radio_aml_transaction_size_aio", indexName: "aioEducationIndex"},
		}),

		_createTableRow.call(this, {
			investment: {
				name: "radio_kyc_transactions_autocalls",
				label: "Avkastningsförbättrande produkter, t.ex. Autocall, Kuponggarantiprodukt",
				indexName: "autocallsTransactionsIndex",
				checkboxName: "autocalls_not_applicable"
			},
			knowledge: {name: "radio_kyc_knowledge_autocalls", indexName: "autocallsKnowledgeIndex"},
			education: {name: "radio_aml_transaction_size_autocalls", indexName: "autocallsEducationIndex"},
		}),

		_createTableRow.call(this, {
			investment: {
				name: "radio_kyc_transactions_derivat",
				label: "Hävstångsprodukter, t.ex. Warranter, Minifutures",
				indexName: "derivativeTransactionsIndex",
				checkboxName: "derivative_not_applicable"
			},
			knowledge: {name: "radio_kyc_knowledge_derivat", indexName: "derivativeKnowledgeIndex"},
			education: {name: "radio_aml_transaction_size_derivat", indexName: "derivativeEducationIndex"},
		}),

		_createTableRow.call(this, {
			investment: {
				name: "radio_kyc_transactions_index_products",
				label: "Indexföljande produkter, t.ex. AMC eller trackercertifikat",
				indexName: "indexProductsTransactionsIndex",
				checkboxName: "index_products_not_applicable"
			},
			knowledge: {name: "radio_kyc_knowledge_index_products", indexName: "indexProductsKnowledgeIndex"},
			education: {name: "radio_aml_transaction_size_index_products", indexName: "indexProductsEducationIndex"},
		})
	];
}

function _getTableColumnsUnderstands()
{
	return [
		{
			key: "investment",
			label: "Förståelse",
			width: "31%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "understands",
			label: "Rådgivaren anser att kunden förstått riskerna med produkterna/tjänsterna",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "not-used",
			label: "",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "not-used2",
			label: "",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		}
	];
}

function _getTableColumnsServices()
{
	return [
		{
			key: "investment",
			label: "",
			width: "31%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "experience",
			label: "Har kunden tidigare erfarenhet?",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "educated",
			label: "Har kunden utbildats?",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "understands",
			label: "Rådgivaren anser att kunden förstått innebörden av tjänsten?",
			width: "23%",
			horizontalAlign: "left",
			verticalAlign: "top"
		}
	];
}


function _getTableRowsServices()
{
	return [
		_createTableRowServices.call(this, {
			product: {label: "Depå"},
			experience: {name: "radio_experience_depa", indexName: "experienceDepa"},
			educated: {name: "radio_education_depa", indexName: "educationDepa"},
			understands: {name: "radio_understands_depa", indexName: "understandsDepa"},
		}),
		_createTableRowServices.call(this, {
			product: {label: "ISK"},
			experience: {name: "radio_experience_isk", indexName: "experienceIsk"},
			educated: {name: "radio_education_isk", indexName: "educationIsk"},
			understands: {name: "radio_understands_isk", indexName: "understandsIsk"}
		}),
		_createTableRowServices.call(this, {
			product: {label: "T-, P- och K-försäkring"},
			experience: {name: "radio_experience_tpk", indexName: "experienceTpk"},
			educated: {name: "radio_education_tpk", indexName: "educationTpk"},
			understands: {name: "radio_understands_tpk", indexName: "understandsTpk"}
		}),
		_createTableRowServices.call(this, {
			product: {label: "Investeringsrådgivning / portföljförvaltning"},
			experience: {name: "radio_experience_investment", indexName: "experienceInvestment"},
			educated: {name: "radio_education_investment", indexName: "educationInvestment"},
			understands: {name: "radio_understands_investment", indexName: "understandsInvestment"}
		})
	];
}

function _getTableRowsUnderstands()
{
	return [
		_createTableRowUnderstands.call(this, {
			insurance: {
				label: "Aktier"
			},
			knowledge: {name: "radio_understands_aktier", indexName: "understandsAktier"}
		}),
		_createTableRowUnderstands.call(this, {
			insurance: {
				label: "Onoterade aktier"
			},
			knowledge: {name: "radio_understands_onot_aktier", indexName: "understandsOnoterat"}
		}),
		_createTableRowUnderstands.call(this, {
			insurance: {
				label: "Investeringsfonder"
			},
			knowledge: {name: "radio_understands_fonder", indexName: "understandsFonder"}
		}),
		_createTableRowUnderstands.call(this, {
			insurance: {
				label: "Kapitalskyddade produkter, t.ex. Aktieindexobligationer"
			},
			knowledge: {name: "radio_understands_aio", indexName: "understandsAio"}
		}),

		_createTableRowUnderstands.call(this, {
			insurance: {
				label: "Avkastningsförbättrande produkter, t.ex. Autocall, Kuponggarantiprodukt"
			},
			knowledge: {name: "radio_understands_autocalls", indexName: "understandsAutocalls"}
		}),
		_createTableRowUnderstands.call(this, {
			insurance: {
				label: "Hävstångsprodukter, t.ex. Warranter, Minifutures"
			},
			knowledge: {name: "radio_understands_derivat", indexName: "understandsDerivat"}
		}),
		_createTableRowUnderstands.call(this, {
			insurance: {
				label: "Indexföljande produkter, t.ex. AMC eller trackercertifikat"
			},
			knowledge: {name: "radio_understands_index_products", indexName: "understandsIndexProducts"}
		})
	];
}

function _createTableRow({investment, knowledge, education})
{
	const {customerState} = this.state;

	return {
		investment: {
			className: "investment",
			value: (
				<React.Fragment>
					{investment.label}

					{investment.checkboxName && (
						<React.Fragment>
							<Checkbox
								className={"table-checkbox"}
								type="checkbox"
								name={investment.checkboxName}
								label="Ingen erfarenhet/kunskap"
								value={customerState[investment.checkboxName] === "yes" ? "yes" : "no"}
								checked={customerState[investment.checkboxName] === "yes"}
								onChange={(checked) => this.setCustomerState({[investment.checkboxName]: checked ? "yes" : "no"},
									() => this.setTableData({
										[investment.name]: "0-5",
										[knowledge.name]: "ingen",
										[education.name]: "0-100"
									}))}
							/>
							<input type={"hidden"} name={investment.name} value={"0-5"}/>
							<input type={"hidden"} name={knowledge.name} value={"ingen"}/>
							<input type={"hidden"} name={education.name} value={"0-100"}/>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		},

		knowledge: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={knowledge.name}
					selectedIndex={customerState[investment.checkboxName] === "yes" ? 0 : customerState[knowledge.indexName]}
					disabled={customerState[investment.checkboxName] === "yes"}
					options={[
						{label: "Ingen", value: "ingen"},
						{label: "Någon", value: "nagon"},
						{label: "Medel", value: "medel"},
						{label: "Hög", value: "hog"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[knowledge.indexName]: index
						}, () => this.setTableData({[knowledge.name]: option.value}))
					}
				/>
			),
		},

		amountOfbusinessLast3Years: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={investment.name}
					selectedIndex={customerState[investment.checkboxName] === "yes" ? 0 : customerState[investment.indexName]}
					disabled={customerState[investment.checkboxName] === "yes"}
					options={[
						{label: "0-5 st/år", value: "0-5"},
						{label: "6-15 st/år", value: "6-15"},
						{label: "16-50 st/år", value: "16-50"},
						{label: "Över 50st/år", value: ">50"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[investment.indexName]: index
						}, () => this.setTableData({[investment.name]: option.value}))
					}
				/>
			),
		},

		education: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={education.name}
					selectedIndex={customerState[investment.checkboxName] === "yes" ? 0 : customerState[education.indexName]}
					disabled={customerState[investment.checkboxName] === "yes"}
					options={[
						{label: "0 - 100'", value: "0-100"},
						{label: "101' - 500'", value: "101-500"},
						{label: "501' - 1500'", value: "501-1500"},
						{label: "Över 1500'", value: ">1500"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[education.indexName]: index
						}, () => this.setTableData({[education.name]: option.value}))
					}
				/>
			)
		}
	};
}


function _createTableRowInsurance({insurance, knowledge})
{
	const {customerState} = this.state;

	return {
		investment: {
			className: "investment",
			value: insurance.label
		},

		knowledge: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={knowledge.name}
					selectedIndex={customerState[knowledge.indexName]}
					options={[
						{label: "Ingen", value: "ingen"},
						{label: "Någon", value: "nagon"},
						{label: "Medel", value: "medel"},
						{label: "Hög", value: "hog"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[knowledge.indexName]: index
						}, () => this.setTableData({[knowledge.name]: option.value}))
					}
				/>
			),
		}
	};
}

function _createTableRowUnderstands({insurance, knowledge})
{
	const {customerState} = this.state;

	return {
		investment: {
			className: "investment",
			value: insurance.label
		},

		understands: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={knowledge.name}
					selectedIndex={customerState[knowledge.indexName]}
					options={[
						{label: "Ja", value: "yes"},
						{label: "Nej", value: "no"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[knowledge.indexName]: index
						}, () => this.setTableData({[knowledge.name]: option.value}))
					}
				/>
			),
		}
	};
}
function _createTableRowServices({product, experience, educated, understands})
{
	const {customerState} = this.state;

	return {
		investment: {
			className: "investment",
			value: product.label
		},

		experience: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={experience.name}
					selectedIndex={customerState[experience.indexName]}
					options={[
						{label: "Ja", value: "yes"},
						{label: "Nej", value: "no"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[experience.indexName]: index
						}, () => this.setTableData({[experience.name]: option.value}))
					}
				/>
			),
		},

		educated: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={educated.name}
					selectedIndex={customerState[educated.indexName]}
					options={[
						{label: "Ja", value: "yes"},
						{label: "Nej", value: "no"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[educated.indexName]: index
						}, () => this.setTableData({[educated.name]: option.value}))
					}
				/>
			),
		},

		understands: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={understands.name}
					selectedIndex={customerState[understands.indexName]}
					options={[
						{label: "Ja", value: "yes"},
						{label: "Nej", value: "no"}
					]}
					onChange={(option, index) =>
						this.setCustomerState({
							[understands.indexName]: index
						}, () => this.setTableData({[understands.name]: option.value}))
					}
				/>
			),
		}
	};
}